import {Button} from "react-bootstrap";

const GoListingPage = (props) => {

  if (props.parentName != 'account_page')  {
    return <Button
            id=""
            onClick={() => {
              props.setScreenMode("Listings Screen");
            }}
          >
            Back to Listings Page
        </Button>
} 
  return <></>
};

export default GoListingPage;
