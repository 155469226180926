// import { toHaveStyle } from "@testing-library/jest-dom/dist/matchers";
import React, { Component } from "react";
// import UkMap from "./uk-map";
import RangeSlider from "react-bootstrap-range-slider";
import { Button } from "react-bootstrap";

class UserInfo extends Component {
  state = {
    value: "",
  };

  changeLongLat() {
    this.setState({
      longitude: 1,
      latitude: 1,
    });
  }

  myOnChange = (e) => {
    this.setState({ selection: e.target.value });
    this.props.setEnquiryType(e.target.value);
  };

  componentDidMount() {
    this.setState({
      selection: this.props.enquiryType,
      value: this.props.bedrooms,
    });
  }

  numBedOnChange = (e) => {
    this.setState({ value: e.target.value });
    this.props.setBedroomNum(e.target.value);
  };

  render() { // console.log(this.state, 'this is the state', this.props, 'this is the props')
    return (
      <>
      <div className="button-holder">
        <div className="sale-button">
          <Button className="main-btn" value="for-sale" onClick={this.myOnChange}>
            Sale
          </Button>
        </div>
        <div className="rent-button">
          <Button className="main-btn" value="to-rent" onClick={this.myOnChange}>
            Rent
          </Button>
        </div>
        </div>
        <div className="slider">
          <p className="slider-label">
            Number of Bedrooms
          </p>
          <RangeSlider
            className="slider-style"
            min={0}
            max={10}
            step={1}
            value={this.state.value}
            onChange={(e) => this.numBedOnChange(e)}
          />
      </div>
      </>
    );
  }
}

export default UserInfo;
