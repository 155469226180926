import React, { Component } from "react";
import ListingsPage from "../listings-screen";

class ListingsApi extends Component {
  state = 
  {
    numBedrooms: "",
    listingId: [],
    originalImage: "",
    imageUrl: "",
    displayableAddress: "",
    screenMode: "",
    resultCount: "",
    maxPages: "",
    fooArray: [],
    status:"",
    showFlag:[],
    userSavedListingId:"",

  };

  componentDidMount() {
    this.listingsData();
  }

  listingsData = () => {
    this.setState({ listingId: this.props.listingId});
    this.setState({ originalImage: this.props.originalImage});
    this.setState({ imageUrl: this.props.imageUrl});
    this.setState({ displayableAddress: this.props.displayableAddress});
    this.setState({ price: this.props.price});
    this.setState({ numBedrooms: this.props.numBedrooms});
    this.setState({ screenMode: 2 }); //set screen mode to 2 if the api request is a success
    this.setState({ resultCount: this.props.resultCount }); // this is for working out how many pages to display ie result count divided by 40 listings per page
    this.setState({ maxPages: Math.round(this.state.resultCount / 40),});
    this.setState({ status: this.props.status});
    this.setState({ showFlag: this.props.showFlag});
    this.setState({ userSavedListingId: this.props.userSavedListingId});

    var foo = [];

    for (var i = 1; i <= this.state.maxPages; i++) {
      foo.push(i);
    }
    this.setState({ fooArray: foo });

  };

  render() { console.log(this.props)
    return (  
      <>
        {this.props.listingId.map((item, index) => {
          return (
            <div className="above-listings-page" key={index}>
              <ListingsPage
                key={index}
                userSavedListingId={item}
                displayableAddress={this.props.displayableAddress[index]}                
                status={this.props.status[index]}
                imageUrl={this.props.imageUrl[index]}
                price={this.props.price[index]}
                numBedrooms={this.props.numBedrooms[index]}
                setScreenMode={this.props.setScreenMode}
                listingId={this.props.listingId[index]}
                setListingId={this.props.setListingId}
                setMyIndex={this.props.setMyIndex}
                myIndex={[index]}
                email={this.props.newEmail}
                showFlag={this.props.showFlag[index]}
                setParentName={this.props.setParentName}
              />
            </div>
          );
        })}
      </>
    );
  }
}

export default ListingsApi;
