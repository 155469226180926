import React, { Component } from "react";
import { Carousel } from "react-bootstrap";

class IndividualImage extends Component {

  render() { console.log(this.props)
    return ( 
      <div >
        <Carousel className="border border-primary" >
          {this.props.originalImage.map((item, index) => {
            return ( 
              <Carousel.Item>
                <div className="border border-primary">
                <img
                  className="carousel-holder d-block img-fluid"
                  src={this.props.originalImage[index]}
                  alt=""
                />
                </div>
                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    );
  }
}
export default IndividualImage;
