import axios from "axios";
import React, { Component } from "react";
import { Button } from "react-bootstrap";

const appUrl = process.env.REACT_APP_API_URL;

class RegistrationPage extends Component {
  state = {
    newForename: "",
    newSurname: "",
    newForenameLength: "",
    newSurnameLength: "",
    newEmail: "",
    data: null,
    message: "",
    message2: "",
    message3: "",
    message4: "",
    message5: "",
    message6: "",
    message7: "",
    message8: "",
    longEnough: false,
    longEnough2: "",
    fnNoNumbers: false,
    fnNoSpecialCharacters: false,
    snNoNumbers: false,
    snNoSpecialCharacters: false,
    emailValid: false,
  };

  registerNewUser = async (e) => {
    if (this.state.emailValid === true) {
    } else {
      this.setState({ data: null });
    }

    const result = await axios.get(`${appUrl}registration/${this.state.newEmail}`);

    if (result.data === true) {
      this.setState({ data: result.data });
      alert("Email already Registered!!!");
    } else {
      this.setState({ data: result.data });
      this.props.setScreenMode(6);
    }
  };

  minLength = (e) => {
    if ((this.state.newForename.length || this.state.newSurname.length) < 1) {
    } else {
      this.setState({ longEnough: true }, () => this.formTest());
    }
  };

  long = () => {
    this.formTest();
  };

  emailTest = (e) => {
    let etest = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    if (etest.test(e.target.value)) {
      this.setState({ message8: "Email is correct format" }, () =>
        this.validateEmail(e)
      );

      this.long();
    } else {
      this.setState({ message8: "i dont think its right" });
      this.setState({ emailValid: false });
    }
  };

  // forename section

  foreNameTest = (e) => {
    this.setState({
      newForename: e.target.value,
    });

    let num = /[0-9]/;
    if (num.test(e.target.value)) {
      this.setState({
        message: "Error: Forenames cannot contain numbers",
      });
    } else {
      this.setState({ message: "" });
      this.setState({ fnNoNumbers: true });
    }
    let up = /^[a-zA-Z0-9\\-]*$/;
    if (!up.test(e.target.value)) {
      this.setState({
        message2: "Error: Forenames cannot contain special characters",
      });
    } else {
      this.setState({ message2: "" });
      this.setState({ fnNoSpecialCharacters: true });
    }
    this.minLength(e);
  };

  foreNameLen = (e) => {
    if (e.target.value.length === 0) {
      this.setState({ message7: "empty" });
      this.long();
    } else {
      this.setState({ message7: "" });
    }
  };

  // surname section
  surNameTest = (e) => {
    this.setState({
      newSurname: e.target.value,
    });

    let num = /[0-9]/;
    if (num.test(e.target.value)) {
      this.setState({
        message3: "Error: Forenames cannot contain numbers",
      });
    } else {
      this.setState({ message3: "" });
      this.setState({ snNoNumbers: true });
    }
    let up = /^[a-zA-Z0-9\\-]*$/;
    if (!up.test(e.target.value)) {
      this.setState({
        message4: "Error: Forenames cannot contain special characters",
      });
    } else {
      this.setState({ message4: "" });
      this.setState({ snNoSpecialCharacters: true });
    }
    this.minLength(e);
  };

  surNameLen = (e) => {
    if (e.target.value.length === 0) {
      this.setState({ message6: "empty" });
      this.long();
    } else {
      this.setState({ message6: "" });
    }
  };

  // email section
  validateEmail = (e) => {
    let etest = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    if (!etest.test(e.target.value)) {
      this.setState(
        {
          message5: "Error: Please enter a valid email",
        },
        () => this.formTest()
      );
    } else {
      this.setState({
        message5: "",
        emailValid: true,
        newEmail: e.target.value,
      });

      this.formTest();
      return true;
    }
  };

  // function to test if the form is fine - if it is then set to true and show button
  formTest = () => {
    if (
      (this.state.longEnough &&
        this.state.fnNoNumbers &&
        this.state.fnNoSpecialCharacters &&
        this.state.snNoNumbers &&
        this.state.snNoSpecialCharacters &&
        this.state.emailValid) === true
    ) {
      this.setState({ formSubmit: true });
    } else {
      this.setState({ formSubmit: false });
    }
  };

  componentDidUpdate() {
    if (this.state.newSurname.length < 1 && this.state.longEnough === true) {
      // if there is a situation where the actual length is zero but the state still thinks it is more than zero
      this.setState({ longEnough: false }, () => this.formTest());
    }

    if (this.state.newForename.length < 1 && this.state.longEnough === true) {
      this.setState({ longEnough: false }, () => this.formTest());
    }
  }

  render() {
    return (
      <div className="registration-form-container">
        <p>Please enter your details:</p>
        <label htmlFor="forename">Forename: </label>
        <input
          id="forename"
          type="text"
          onInput={(e) => {
            this.foreNameTest(e);
            this.foreNameLen(e);
          }}
        ></input>
        <br></br>
        {this.state.message}
        <br></br>
        {this.state.message2}
        <br></br>
        {this.state.message7}
        <br></br>
        <label htmlFor="">Surname: </label>
        <input
          type="text"
          onInput={(e) => {
            this.surNameTest(e);
            this.surNameLen(e);
          }}
        ></input>
        <br></br>
        {this.state.message3}
        <br></br>
        {this.state.message4}
        <br></br>
        {this.state.message6}
        <br></br>
        <label htmlFor="">Email </label>
        <input
          type="email"
          onInput={(e) => {
            this.validateEmail(e);
            this.emailTest(e);
          }}
        ></input>
        <br></br>
        {this.state.message5}
        <br></br>
        {this.state.message8}
        <br></br>
        {this.state.formSubmit === true ? (
          <Button
            as="input"
            type="button"
            value="Submit"
            onClick={() => {
              this.registerNewUser();
              this.props.setNewEmail(this.state.newEmail);
              this.props.setNewForename(this.state.newForename);
              this.props.setNewSurname(this.state.newSurname);
            }}
          />
        ) : (
          <p></p>
        )}
      </div>
    );
  }
}

export default RegistrationPage;
