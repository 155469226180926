import axios from "axios";
const appUrl = process.env.REACT_APP_API_URL;

export const getListingDataFromSql = async (email) => {
    
  try {
    const result = await axios.get(
        `${appUrl}getsavedlisting/${email}`,        
        {}

      );
      console.log(result);
      return result;
    }
    catch(e) {
      console.log(e)
    }
};

export default getListingDataFromSql