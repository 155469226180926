import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const submitPostcode = async (postcode) => {
    // console.log(apiUrl, '<<<<------',`${apiUrl}searchpostcode/${postcode}`);
    try {
    const result = await axios.get(
      `${apiUrl}searchpostcode/${postcode}`
    );
    // console.log(result, '<-- get the result')
    return result;
  }
  catch(error) {
    console.log(error);
  };
  };

  export default submitPostcode;