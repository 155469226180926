import React, { Component } from "react";
// import axios from "axios";
// import AccountListingsPage from ".././accountListingPage/account-listings-page";
import ListingsPage from "../../listingsScreen/listings-screen";
import getListingDataFromSql from "../../../listingQuery"

class AccountPage extends Component {
  state = {
    address: [],
    agentBranchId:[],
    agentBranchName:[],
    agentLogoUri:[],
    agentPhone:[],
    bathrooms:[],
    bedrooms:[],
    email:[],
    flag:[],
    imageUris:[],
    isExpired:[],
    isFeatured:[],
    isPremium:[],
    listingId:[],
    label:[],
    longLat:[],
    latitude:[],
    livingRooms:[],
    longitude:[],
    publicationStatus:[],
    qualifier:[],
    qualifierLabel:[],
    showFlag:[],
    tags:[],
    title:[],
    value:[],
    thumbNail:[]
  };
  
  setShowFlag = () => {
    this.setState({ showFlag: 1 });
  };

  capture = (result) => {
      const dataEntries = result.data;
      console.log(dataEntries.map((entry) => entry.address))
      console.log(dataEntries[0].imageUris.split(",")[0])
  
    this.setState({
      address: dataEntries.map((entry) => entry.address),
      agentBranchId: dataEntries.map((entry) => entry.agent_branchId),
      agentBranchName: dataEntries.map((entry) => entry.agent_branchName),
      agentLogoUri: dataEntries.map((entry) => entry.agent_logoUri),
      agentPhone: dataEntries.map((entry) => entry.agent_phone),
      bathrooms: dataEntries.map((entry) => entry.bathrooms),
      bedrooms: dataEntries.map((entry) => entry.bedrooms),
      email: dataEntries.map((entry) => entry.email),
      flag: dataEntries.map((entry) => entry.flag),
      imageUris: dataEntries.map((entry) => entry.imageUris.split(",")),
      isExpired: dataEntries.map((entry) => entry.isExpired),
      isFeatured: dataEntries.map((entry) => entry.isFeatured),
      isPremium: dataEntries.map((entry) => entry.isPremium),
      listingId: dataEntries.map((entry) => entry.listing_id),
      label: dataEntries.map((entry) => entry.label),
      longLat: dataEntries.map((entry) => [entry.longitude, entry.latitude]),
      latitude: dataEntries.map((entry) => entry.latitude),
      livingRooms: dataEntries.map((entry) => entry.livingRooms),
      longitude: dataEntries.map((entry) => entry.longitude),
      publicationStatus: dataEntries.map((entry) => entry.publicationStatus),
      qualifier: dataEntries.map((entry) => entry.qualifier/*.split(",")*/),
      qualifierLabel: dataEntries.map((entry) => entry.qualifierLabel),
      showFlag: dataEntries.map((entry) => entry.show_flag),
      tags: dataEntries.map((entry) => entry.tags),
      title: dataEntries.map((entry) => entry.title),
      value: dataEntries.map((entry) => entry.value),
      thumbNail: dataEntries.map((entry) => entry.imageUris.split(",")),
    });
  
    this.props.setSavedListingState(this.state);
  };
  
  getData = async () => {
    let result = await getListingDataFromSql(this.props.email)
    this.capture(result);
  };

  componentDidMount() {
    // this.setShowFlag();
    // console.log('this.getData()');
    this.getData();
  }



  render() { console.log('this.props', this.props);
    return ( 
      <div>
        {this.state.listingId.map((item, index) => {
          return ( 
            // <div key={item.id}>{console.log(this.state)}
            <div key={item.listingId}> 
            {/* {this.state.address[index]}
            {this.state.thumbNail[index]}
            {'THIS IS THE ' + index + ' INDEX'} */}
            <ListingsPage 
              parentComponentName={'account_page'}
              imageUrl={this.state.thumbNail[index][0]}
              displayableAddress={this.state.address[index]}
              listingId={item}
              // listingType={this.state.status[index]}
              // imageUrl={this.state.imageUris[index]}
              price={this.state.value[index]}
              // numBedrooms={this.state.bedrooms[index]}
              setScreenMode={this.props.setScreenMode}
              setListingId={this.props.setListingId}
              setMyIndex={this.props.setMyIndex}
              // individualListingId={this.state.listingId[index]}
              myIndex={[index]}
              // email={this.props.email}
              // listingStatus={this.state.publicationStatus[index]} 
              // originalImage={this.state.imageUris[0]}
              // thumbnailUrl={this.state.imageUris[index]}
              showFlag={this.state.showFlag[index]}
              updateStateWithSavedListings={this.props.updateStateWithSavedListings}
              setParentName={this.props.setParentName}
            />
            </div>
          );
        })}
      </div>
    );
  }
}

export default AccountPage;
