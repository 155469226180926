import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";

class SortListing extends Component {
  state = {
    options: [],
    optionsOrder: [],
    ordering: "",
    sort: "",
    order: "",
    currentOption: "",
  };

  componentDidMount() {
    if (this.props.sort === "newest_listings") {
      this.setState({
        options: [ "Highest Price", "Lowest Price", "Most Reduced"],
        optionsOrder: [
          "Newest Listings",
          "Highest Price",
          "Lowest Price",
          "Most Reduced",
        ],
        currentOption: "Newest Listings",
      });
    }

    if (this.props.sort === "highest_price") {
      this.setState({
        options: [ "Newest Listings", "Lowest Price", "Most Reduced"],
        optionsOrder: [
          "Newest Listings",
          "Highest Price",
          "Lowest Price",
          "Most Reduced",
        ],
        currentOption: "Highest Price",
      });
    }

    if (this.props.sort === "lowest_price") {
      this.setState({
        options: [ "Newest Listings","Highest Price", "Most Reduced"],
        optionsOrder: [
          "Newest Listings",
          "Highest Price",
          "Lowest Price",
          "Most Reduced",
        ],
        currentOption: "Lowest Price",
      });
    }

    if (this.props.sort === "most_reduced") {
      this.setState({
        options: ["Newest Listings" ,"Highest Price", "Lowest Price"],
        optionsOrder: [
          "Newest Listings",
          "Highest Price",
          "Lowest Price",
          "Most Reduced",
        ],
        currentOption: "Most Reduced",
      });
    }
  }

  sendOption = (item) => {
    let myItem = item;
    console.log(myItem);
    if (myItem === "Newest Listings") {
      this.setState({ sort: "newest_listings"}, () => {
        this.props.sortBy(this.state.sort);
      });
    }
    if (myItem === "Highest Price") {
      this.setState({ sort: "highest_price"}, () => {
        this.props.sortBy(this.state.sort);
      });
    }
    if (myItem === "Lowest Price") {
      this.setState({ sort: "lowest_price"}, () => {
        this.props.sortBy(this.state.sort);
      });
    }
    if (myItem === "Most Reduced") {
      this.setState({ sort: "most_reduced"}, () => {
        this.props.sortBy(this.state.sort);
      });
    }
  };

  render() { // console.log(this.props)
    return ( 
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Sort By : {this.state.currentOption}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {this.state.optionsOrder.map((item, key) => (
            <Dropdown.Item
              href="#/action-1"
              key={item}
              onClick={() => {
                this.sendOption(item);
              }}
            >
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default SortListing;
