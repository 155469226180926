import React, { Component } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import LoginError from "../unusedComponents/username-password";

const appUrl = process.env.REACT_APP_API_URL;

class LogIn extends Component {
  state = {
    email: "",
    password: "",
    passwordTest: false ,
    rememberMe: false,
    user: "",
    screenMode: 0,
  };

  findEmail = async () => {
    const result = await axios.get(
      `${appUrl}log_in/${this.state.email}/${this.state.password}`      
    )
        if (result.data === true) {
        this.props.setNewEmail(this.state.email);
        this.props.setScreenMode(1);
        }
};

  updateData = (res) => {
    this.setState({passwordTest: res});
  };



  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    this.setState({ [input.name]: value });
  };

  handleFormSubmit = () => {
    const user = this.state.email;
    this.props.setScreenMode(1);
    const rememberMe = this.state.rememberMe;
    localStorage.setItem("rememberMe", rememberMe);
    localStorage.setItem("user", rememberMe ? user : "");
  };

  componentDidMount() {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const user = rememberMe ? localStorage.getItem("user") : "";
    this.setState({ user, rememberMe });
  };

  render() { console.log(this.state)
    return (
      <div>
        <h1
          style={{
            backgroundColor: "whitesmoke",
            borderRadius: "5px",
            fontSize: "15px",
            width: "fit-content",
          }}
        >
          Please Log In to the Home Finder App
        </h1>
        <div>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "whitesmoke",
              borderRadius: "5px",
              gap: "5px",
              justifyContent: "space-evenly",
            }}
            onSubmit={this.handleFormSubmit}
          >
            <label style={{ alignSelf: "center" }} htmlFor="user_box">User</label>
            <input
              id="user_box"
              value={this.state.email}
              onChange={this.handleChange}
              onInput={(e) => this.setState({ email: e.target.value })}
            />
            <label style={{ alignSelf: "center" }} htmlFor="log_in_password_box">Password</label>
            <input
              type="password"
              id="log_in_password_box"
              onInput={(e) => this.setState({ password: e.target.value })}
            ></input>
            <label style={{ alignSelf: "center" }} htmlFor="remember_me"> Remember me</label>
            <input
              id="remember_me"
              checked={this.state.rememberMe}
              onChange={this.handleChange}
              type="checkbox"
            />
            <Button
              id="logIn"
              onClick={() => {
                    this.findEmail();
              }}
            >
              Log In
            </Button>

            <Button onClick={() => this.props.setScreenMode(5)}>
              Register/Sign-up
            </Button>
          </form>
        </div>
        {this.state.data === true || this.state.data === null ? (
          <p></p>
        ) : (
          <LoginError />
        )}
      </div>
    );
  }
}

export default LogIn;
