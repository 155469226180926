import axios from "axios";

const appUrl = process.env.REACT_APP_API_URL; 

export async function fetchApiData(propertiesListArea, bedrooms, page, enquiryType, sortBy) {
  console.log(propertiesListArea, bedrooms, page, enquiryType,sortBy);
    const result = await axios.get(
        `${appUrl}getApiData/${propertiesListArea}/${bedrooms}/${page}/${enquiryType}/${sortBy}`,
        {}
      );
      console.log(result,typeof(result),'result from API');
      return result;
    }