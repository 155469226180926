import React, { Component } from "react";
import { Button } from "react-bootstrap";


class GoUserInfo extends Component {
  state = {};
  render() {
    return (
      <Button className="back-to-search-page"
        id=""
        onClick={() => {
          this.props.setScreenMode(1);
        }}
      >
        Back to Search Page
      </Button>
    );
  }
}

export default GoUserInfo;
