import React from "react";
import { Button } from "react-bootstrap";

const SubmitSearchButton = (props) => {

  // console.log('submit button', props);
    return ( 
          <Button 
            onClick={() => {
              props.sendSearchTerm(props.searchOutcode);
              // props.sendSearchTime(props.currentTime);
              // console.log(props, 'this is the props');
            }}
          >
            Submit Search
          </Button>

    );
};

export default SubmitSearchButton;